import { getRegistrationSummary } from '@/api/v2/gm/shareClassRegistrationSummary';
import { trimEncodedKey } from '@/lib/encodedKey';
import './registrations-tab.scss';

angular
  .module('generalMeetingMainModule')
  .controller('RegistrationTabController', [
    '$scope',
    '$state',
    '$sessionStorage',
    'generalMeetingService',
    '$filter',
    '$log',
    'ModelWindowService',
    'fileDownloadSvc',
    'cbpDownloadBlob',
    '$stateParams',
    '$timeout',
    '$http',
    function (
      $scope,
      $state,
      $sessionStorage,
      generalMeetingService,
      $filter,
      $log,
      ModelWindowService,
      fileDownloadSvc,
      cbpDownloadBlob,
      $stateParams,
      $timeout,
      $http
    ) {
      $scope.meetingStatus = $sessionStorage.status;
      $scope.extnRequestModelConfig = {};
      $scope.previewProxy = '';
      $scope.extnRequestModelConfig.modalHeader = 'Download Error';
      $scope.extnRequestModelConfig.modalBody =
        'Please update a Security Class and save it, before downloading the forms';
      $scope.userId = $sessionStorage.userId;
      $scope.languageCode = $scope.languageCode ? $scope.languageCode : 'EN';
      $sessionStorage.currentTab = null;
      $scope.highlightRegDownloadLink = false;
      if (
        $sessionStorage.actionFor &&
        $sessionStorage.actionFor === 'regList'
      ) {
        $scope.highlightRegDownloadLink = true;
        $sessionStorage.actionFor = null;
      }
      $scope.options = {
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var allData = data.datasets[tooltipItem.datasetIndex].data;
              var tooltipData = allData[tooltipItem.index];
              return tooltipData + '%';
            },
          },
        },
      };
      $scope.colours = [
        {
          backgroundColor: 'rgba(255,204,17,1)',
        },
        {
          backgroundColor: 'rgba(79, 112, 111, 1)',
        },
        {
          backgroundColor: 'rgba(96,142,40,1)',
        },
        {
          backgroundColor: 'rgba(255, 127, 80,1)',
        },
        {
          backgroundColor: 'rgba(255, 20, 147,1)',
        },
        {
          backgroundColor: 'rgba(51,51,51,1)',
        },
        {
          backgroundColor: 'rgba(153,153,153,1)',
        },
      ];

      this.$onInit = () => {
        $scope.loadingRegistrationData = true;

        // We want to display a 'total' option
        $scope.shareClassList = $sessionStorage.gmShareclassList;
        $scope.shareClassOptions = [
          { name: 'All', value: 'all' },
          ...$scope.shareClassList.map((gmSc) => ({
            name: gmSc.shareClassName,
            value: gmSc.shareClassId,
          })),
        ];

        if ($scope.shareClassList.length <= 1) {
          // If there's only one option, don't preselect the 'all' option
          $scope.selectedShareClassId = $scope.shareClassOptions[1].value;
        } else {
          $scope.selectedShareClassId = $scope.shareClassOptions[0].value;
        }
        $scope.selectedShareClass = $scope.shareClassList.find(
          (shareClass) =>
            shareClass.shareClassId === $scope.selectedShareClassId
        );

        this.loadRegistrationSummaries().finally(() => {
          $scope.loadingRegistrationData = false;

          $scope.selectedRegistrationSummary =
            $scope.registrationSummaryData.total;

          $scope.recalculateTotals();

          $scope.$apply();
        });
      };

      this.loadRegistrationSummaries = async () => {
        $scope.registrationSummaryData = await getRegistrationSummary(
          $http,
          $stateParams.meetingId
        );
      };

      $scope.handleShareClassChange = function (ev) {
        const { value } = ev.detail;
        if (value === 'all') {
          $scope.selectedRegistrationSummary =
            $scope.registrationSummaryData.total;
        } else {
          $scope.selectedRegistrationSummary = $scope.registrationSummaryData.shareClassRegistrations.find(
            (shareClassRegistration) =>
              shareClassRegistration.shareClassId === trimEncodedKey(value)
          );
        }
        $scope.selectedShareClassId = value;
        $scope.selectedShareClass = $scope.shareClassList.find(
          (shareClass) =>
            shareClass.shareClassId === $scope.selectedShareClassId
        );
        $scope.recalculateTotals();
      };

      $scope.recalculateTotals = () => {
        let {
          arShareholderCount = 0,
          viPrxyShareholderCount = 0,
          viEvotShareholderCount = 0,
          vrShareholderCount = 0,
          arShareCount = 0,
          viPrxyShareCount = 0,
          viEvotShareCount = 0,
          vrShareCount = 0,
          arVotingRightsCount = 0,
          viPrxyVotingRightsCount = 0,
          viEvotVotingRightsCount = 0,
          vrVotingRightsCount = 0,
          outstandingVotingRights,
          abstainViPrxyVotingRightsCount = 0,
          againstVotesViPrxyVotingRightsCount = 0,
          forVotesViPrxyVotingRightsCount = 0,
          abstainViEvotVotingRightsCount = 0,
          againstVotesViEvotVotingRightsCount = 0,
          forVotesViEvotVotingRightsCount = 0,
        } = $scope.selectedRegistrationSummary;

        $scope.totalInstructions =
            arShareholderCount + viPrxyShareholderCount + viEvotShareholderCount
            + vrShareholderCount;
        $scope.totalSecurities = arShareCount + viPrxyShareCount
            + viEvotShareCount + vrShareCount;
        $scope.totalVotingRights =
            arVotingRightsCount + viPrxyVotingRightsCount
            + viEvotVotingRightsCount + vrVotingRightsCount;

        let agendaItemCount =
            (againstVotesViPrxyVotingRightsCount
                + againstVotesViEvotVotingRightsCount +
                forVotesViPrxyVotingRightsCount
                + forVotesViEvotVotingRightsCount +
                abstainViPrxyVotingRightsCount + abstainViEvotVotingRightsCount)
            / (viPrxyVotingRightsCount + viEvotVotingRightsCount);

        agendaItemCount = isNaN(agendaItemCount) ? 0 : agendaItemCount;

        $scope.totalAttendanceShare =
          (arVotingRightsCount / outstandingVotingRights) * 100;
        $scope.totalVirtualAttendanceShare =
          (vrVotingRightsCount / outstandingVotingRights) * 100;
        $scope.nonAbstainedVIsubmitted =
            ((againstVotesViPrxyVotingRightsCount
                    + againstVotesViEvotVotingRightsCount +
                    + forVotesViPrxyVotingRightsCount
                    + forVotesViEvotVotingRightsCount) /
                (outstandingVotingRights * agendaItemCount)) * 100;
        $scope.nonAbstainedPrxyVIsubmitted =
            ((againstVotesViPrxyVotingRightsCount +
                     forVotesViPrxyVotingRightsCount) /
                (outstandingVotingRights * agendaItemCount)) * 100;
        $scope.nonAbstainedEvotVIsubmitted =
            ((againstVotesViEvotVotingRightsCount +
                    forVotesViEvotVotingRightsCount) /
                (outstandingVotingRights * agendaItemCount)) * 100;
        $scope.abstainedPrxyVIsubmitted =
            (abstainViPrxyVotingRightsCount /
                (outstandingVotingRights * agendaItemCount)) * 100;
        $scope.abstainedEvotVIsubmitted =
            (abstainViEvotVotingRightsCount /
                (outstandingVotingRights * agendaItemCount)) * 100;
        $scope.abstainedVIsubmitted =
            ((abstainViPrxyVotingRightsCount + abstainViEvotVotingRightsCount) /
                (outstandingVotingRights * agendaItemCount)) * 100;

        $scope.assignZeroIfCalculatedValuesAreNan();

        $scope.noAction =
          100 -
          $scope.totalAttendanceShare -
          $scope.totalVirtualAttendanceShare -
          $scope.nonAbstainedPrxyVIsubmitted -
          $scope.nonAbstainedEvotVIsubmitted -
          $scope.abstainedPrxyVIsubmitted -
          $scope.abstainedEvotVIsubmitted;

        $scope.setChartData({
          datasets: [
            {
              data: [
                $scope.totalAttendanceShare,
                $scope.totalVirtualAttendanceShare,
                $scope.nonAbstainedPrxyVIsubmitted,
                $scope.abstainedPrxyVIsubmitted,
                $scope.nonAbstainedEvotVIsubmitted,
                $scope.abstainedEvotVIsubmitted,
                $scope.noAction,
              ],
              backgroundColor: $scope.colours.map((c) => c.backgroundColor),
            },
          ],
        });
      };

      /**
       * Assigns zero to calculated numbers if they are NaN (Not-a-Number).
       */
      $scope.assignZeroIfCalculatedValuesAreNan = () => {
        $scope.nonAbstainedVIsubmitted = isNaN($scope.nonAbstainedVIsubmitted)
            ? 0 : $scope.nonAbstainedVIsubmitted;
        $scope.nonAbstainedPrxyVIsubmitted = isNaN(
            $scope.nonAbstainedPrxyVIsubmitted)
            ? 0 : $scope.nonAbstainedPrxyVIsubmitted;
        $scope.nonAbstainedEvotVIsubmitted = isNaN(
            $scope.nonAbstainedEvotVIsubmitted)
            ? 0 : $scope.nonAbstainedEvotVIsubmitted;
        $scope.abstainedPrxyVIsubmitted = isNaN(
            $scope.abstainedPrxyVIsubmitted)
            ? 0 : $scope.abstainedPrxyVIsubmitted;
        $scope.abstainedEvotVIsubmitted = isNaN(
            $scope.abstainedEvotVIsubmitted)
            ? 0 : $scope.abstainedEvotVIsubmitted;
        $scope.abstainedVIsubmitted = isNaN($scope.abstainedVIsubmitted)
            ? 0 : $scope.abstainedVIsubmitted;
      };

      $scope.setChartData = (chartData) => {
        $scope.chartData = null;
        $timeout(() => {
          $scope.chartData = chartData;
        });
      };

      $scope.SelectLanguageModal = function (
        modalFor,
        shareClassId,
        previewProxy
      ) {
        $scope.modalBody = '';
        $scope.modalFor = modalFor;
        if (previewProxy === 'N' || previewProxy === 'Y') {
          $scope.previewProxy = previewProxy;
        }
        if (!$sessionStorage.gmShareclassList) {
          ModelWindowService.showModelWindow('shareHolderRequiredAlertBox');
        } else {
          if (modalFor === 'P') {
            ModelWindowService.showModelWindow(
              'downloadRegistrationCertificatePreviewLanguageSelection'
            );
          } else if (modalFor === 'A') {
            ModelWindowService.showModelWindow(
              'downloadAllRegistrationCertificatesLanguageSelection'
            );
          }
        }
      };

      $scope.cancelExcelDownload = () => {
        $scope.fileloading = false;
        $scope.downloadExcel = '';
      };

      $scope.downloadRegListExcel = function (shareClassId) {
        $scope.downloadRegList(shareClassId, 'excel');
      };

      $scope.downloadRegListPdf = function (shareClassId) {
        $scope.downloadRegList(shareClassId, 'pdf');
      };

      $scope.downloadRegList = function (shareClassId, format) {
        if (!$sessionStorage.gmShareclassList) {
          ModelWindowService.showModelWindow('shareHolderRequiredAlertBox');
        } else {
          $scope.fileloading = true;

          let downloadLink = `/v1/general-meetings/${$scope.meetingId}/registrations-reports?format=${format}`;
          if (shareClassId !== 'all') {
            downloadLink += `&shareclasses=${shareClassId}`;
          }

          fileDownloadSvc.downloadFile(downloadLink).then(
            function (response) {
              if (!$scope.fileloading) {
                // Request has been cancelled in the mean time
                return;
              }
              cbpDownloadBlob(response.file, response.fileName);
              $scope.fileloading = false;
            },
            function (error) {
              $log.debug(error);
              $scope.fileloading = false;
            }
          );
        }
      };

      //Below is the function call to download the registration preview
      $scope.downloadRegistrationCertificatePreview = function (shareClassId) {
        $scope.fileloading = true;
        let downloadUrl =
          `/v1/shareclasses/${shareClassId}/general-meetings/${$scope.meetingId}/registration-certificate-preview`;

        downloadUrl += `?language=${$sessionStorage.languageSelected}&withProxy=${$scope.previewProxy}`;

        fileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            if (!$scope.fileloading) {
              // Request has been cancelled in the mean time
              return;
            }

            cbpDownloadBlob(response.file, response.fileName);
            $scope.previewProxy = '';
            $scope.fileloading = false;
          },
          function (error) {
            $log.debug(error);
            $scope.previewProxy = '';
            $scope.fileloading = false;
          }
        );
      };

      //Below is the function call to download the registration zip file
      $scope.downloadAllRegistrationCertificates = function (shareClassId) {
        $scope.fileloading = true;
        let downloadUrl =
          `/v1/shareclasses/${shareClassId}/general-meetings/${$scope.meetingId}/all-registration-certificates`;

        downloadUrl += `?language=${$sessionStorage.languageSelected}`;

        fileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            cbpDownloadBlob(response.file, response.fileName);
            $scope.fileloading = false;
          },
          function (error) {
            $log.debug(error);
            $scope.fileloading = false;
          }
        );
      };

      //Below is the function call to download the Intermediary registration report
      $scope.downloadIntermediaryReport = function (shareClassId) {
        if (!$sessionStorage.gmShareclassList) {
          ModelWindowService.showModelWindow('shareHolderRequiredAlertBox');
        } else {
          $scope.fileloading = true;
          let downloadIntermediaryExcelUrl = `/v1/general-meetings/${$scope.meetingId}/registrations-reports-by-intermediary`;

          if (shareClassId !== 'all') {
            downloadIntermediaryExcelUrl += `?shareclasses=${shareClassId}`;
          }

          fileDownloadSvc.downloadFile(downloadIntermediaryExcelUrl).then(
            function (response) {
              if (!$scope.fileloading) {
                // Request has been cancelled in the mean time
                return;
              }
              cbpDownloadBlob(response.file, response.fileName);
              $scope.fileloading = false;
            },
            function (error) {
              $log.debug(error);
              $scope.fileloading = false;
            }
          );
        }
      };

      $scope.downloadAttestationReport = function (shareClassId) {
        $scope.fileloading = true;

        let downloadUrl = `/v2/gm/agent/general-meetings/${$scope.meetingId}/attestation-reports`;
        if (shareClassId !== 'all') {
          downloadUrl += `?shareclasses=${shareClassId}`;
        }

        fileDownloadSvc.downloadFile(downloadUrl).then(
          function (response) {
            if (!$scope.fileloading) {
              return;
            }
            cbpDownloadBlob(response.file, response.fileName);
            $scope.fileloading = false;
          },
          function (error) {
            $log.debug(error);
            $scope.fileloading = false;
          }
        );
      };

      //Below is the function call to download the Download BO document
      $scope.downloadBOdocument = function () {
        $scope.downloadBOdocumentZip =
          window.location.origin +
          '/v1/shareclasses/' +
          $scope.meetingId +
          '/' +
          $scope.languageCode +
          '/download';
      };

      //Below is the function call to navigate to view attendance page
      $scope.navigateToAttendanceRequests = function (shareClassId) {
        $scope.currentShareClass = shareClassId;
        $state.go('viewAttendance', {
          type: 'attendance',
          shareclassId: shareClassId,
          meetingId: $scope.meetingId,
          status: $scope.status,
          gmDateTime: $scope.gmDateTime,
        });
      };

      //Below is the function call to navigate to view voting instruction page
      $scope.navigateToVotingInstruction = function (shareClassId, participationMethod) {
        $scope.currentShareClass = shareClassId;
        $state.go('viewVoting', {
          type: 'votingInstruction',
          landingThrough: 'registrationTab',
          shareclassId: shareClassId,
          meetingId: $scope.meetingId,
          status: $scope.status,
          gmDateTime: $scope.gmDateTime,
          participationMethod: participationMethod
        });
      };

      // navigate to view virtual participation page
      $scope.navigateToVirtualParticipations = function (shareClassId) {
        $scope.currentShareClass = shareClassId;
        $state.go('viewVirtualParticipations', {
          type: 'virtualParticipation',
          landingThrough: 'registrationTab',
          shareclassId: shareClassId,
          meetingId: $scope.meetingId,
          status: $scope.status,
          gmDateTime: $scope.gmDateTime,
        });
      };

      //Below is the function call on change of language
      $scope.$on('onLanguageChange', function (event, lang) {
        $scope.languageCode = lang;
        $scope.selectedShareClass = $scope.shareClassList[0];
      });

      //SFCBP-2638 Below is the function call to navigate to shareplan invites list page
      $scope.viewShareplanInvite = function (shareClassId) {
        $state.go('viewShareplan', {
          meetingId: $scope.meetingId,
          shareclassId: shareClassId,
          gmDateTime: $scope.gmDateTime,
        });
      };
    },
  ]);
