/**
 * @ngdoc factory
 * @name commonModule.attendanceRequestService
 * @description This is a factory used for attendance request related REST calls
 * @requires $http for POST request
 * @requires $resource for other REST calls.
 */
(function () {
  'use strict';
  angular.module('commonModule').factory('attendanceRequestService', [
    '$http',
    '$resource',
    function ($http, $resource) {
      return {
        getAttendanceRequestView: function (
          meetingId,
          languageCode,
          sharePlanId,
          workStation
        ) {
          return $resource(
            'v1/generalmeetings/registration/' +
              meetingId +
              '/' +
              languageCode +
              '/' +
              sharePlanId +
              '/' +
              workStation,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getShareholderAccess: function (meetingId, languageCode, requestType) {
          return $resource(
            'v1/shareholders/generalmeetings/shareclasses/' +
              meetingId +
              '/' +
              requestType,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getAttendanceRequest: function (attendanceReqId) {
          return $resource(
            'v1/generalmeetings/intermediary/attendances/' + attendanceReqId,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getshareholderRequest: function (
          type,
          statusType,
          groupId,
          gmId,
          langCode,
          pageNo,
          searchFlag,
          searchString
        ) {
          if (!searchString) {
            searchString = 'ALL';
          }
          return $resource(
            'v1/shareholderrequests/' +
              type +
              '/' +
              statusType +
              '/' +
              groupId +
              '/' +
              gmId +
              '/' +
              langCode +
              '/' +
              pageNo +
              '/100' +
              '?sf=' +
              searchFlag +
              '&ss=' +
              searchString,
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        getshareholderRequestCount: function (
          type,
          groupId,
          gmId,
          langCode,
          searchFlag,
          searchString
        ) {
          if (!searchString) {
            searchString = 'ALL';
          }
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/shareholderrequests/' +
              type +
              '/' +
              groupId +
              '/' +
              gmId +
              '/' +
              langCode +
              '?sf=' +
              searchFlag +
              '&ss=' +
              searchString,
            method: 'GET',
          });
        },
        sendResendRegTkt: function (
          shareholderId,
          attendanceReqId,
          gmId,
          shareClassId,
          intermedairyId,
          langCode,
          emailSentFlag
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/shareholderrequests/' +
              shareholderId +
              '/' +
              attendanceReqId +
              '/' +
              gmId +
              '/' +
              shareClassId +
              '/' +
              intermedairyId +
              '/' +
              langCode +
              '/' +
              emailSentFlag +
              '/sendEmailToSH',
            method: 'GET',
          });
        },
        updateShareholderVoteRequest: function (voteData, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/shareholderrequests/vote',
            method: 'PUT',
            data: voteData,
          });
        },
        updateShareholderAttendRequest: function (attendData, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/shareholderrequests/attend',
            method: 'PUT',
            data: attendData,
          });
        },
        getProxyList: function (
          meetingId,
          securityAccNo,
          shareClassId,
          shareholderId,
          userType,
          intermediaryId
        ) {
          return $resource(
            'v1/generalmeetings/registration/' +
              meetingId +
              '/attendances/' +
              securityAccNo +
              '/' +
              shareClassId +
              '/' +
              shareholderId +
              '/' +
              intermediaryId +
              '/' +
              userType,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        saveAttendanceRequestIntermediary: function (
          attendanceRequestData,
          authtoken
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/generalmeetings/intermediary/attendances',
            method: 'POST',
            data: attendanceRequestData,
          });
        },
        saveAttendanceRequest: function (attendanceRequestData, authtoken) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/generalmeetings/registration/attendances',
            method: 'POST',
            data: attendanceRequestData,
          });
        },
        validateIntermediary: function (internediaryId, workStation) {
          return $resource(
            'v1/voteinstructions/validate/' +
              internediaryId +
              '/' +
              workStation +
              '/intermediary',
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getAvailableProxyList: function (shareholderId) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url: 'v1/shareholderusers/' + shareholderId,
            method: 'GET',
          });
        },
        getBulkUploadARDetails: function (reqId, languageCode) {
          return $resource(
            'v1/generalmeetings/registration/' +
              reqId +
              '/' +
              languageCode,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getAttendRequestCount: function (
          meetingId,
          shareClassId,
          languageCode,
          wsType
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/generalmeetings/registration/' +
              meetingId +
              '/' +
              shareClassId +
              '/' +
              languageCode +
              '/' +
              wsType +
              '/maxARAllowed',
            method: 'GET',
          });
        },

        getLinkedMeetingList: function (
          gmId,
          shareClassId,
          intermediaryId,
          requestType,
          languageCode
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            url:
              'v1/general-meetings/' +
              gmId +
              '/shareclasses/' +
              shareClassId +
              '/intermediaries/' +
              intermediaryId +
              '/request-types/' +
              requestType +
              '/linked-meetings?languageCode=' +
              languageCode,
            method: 'GET',
          });
        },
        getShareClassList: function (gmId) {
          return $resource(
            `/v1/gm/intermediary/general-meetings/${gmId}/attendance-requests-shareclasses`,
            {},
            {
              query: {
                method: 'GET',
                isArray: true,
              },
            }
          );
        },
        virtualParticipationSearchCount: function (
          gmId,
          intermediaryId,
          searchParam
        ) {
          return $resource(
            '/v1/generalMeeting/virtualAttendance/statusCount?gmId=' +
              gmId +
              '&intermediaryId=' +
              intermediaryId +
              '&filter=' +
              searchParam,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        getVirtualParticipationRecords: function (
          gmId,
          intermediaryId,
          pageNo,
          pageSize,
          status,
          sortingOrder,
          sortBy,
          searchParam
        ) {
          return $resource(
            'v1/generalMeeting/virtualAttendance/list?gmId=' +
              gmId +
              '&intermediaryId=' +
              intermediaryId +
              '&status=' +
              status +
              '&pageNumber=' +
              pageNo +
              '&pageSize=' +
              pageSize +
              '&sortDirection=' +
              sortingOrder +
              '&sortBy=' +
              sortBy +
              '&filter=' +
              searchParam,
            {},
            {
              query: {
                method: 'GET',
                isArray: false,
              },
            }
          );
        },
        updateVirtualParticipationRegistration: function (
          registrationData,
          authtoken
        ) {
          return $http({
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Internal-Auth-Token': authtoken,
            },
            url: 'v1/generalMeeting/virtualAttendance',
            method: 'PUT',
            data: registrationData,
          });
        },
      };
    },
  ]);
})();
